import React from "react"
import { Subscription } from "@lesmills/gatsby-theme-common"

const RetailSubscriptions = ({
  retailSubs,
  ...rest
}: {
  retailSubs: Array<Object>,
}) => (
  <>
    {retailSubs.map(item => (
      <Subscription subscription={item} key={item.productHandle} {...rest} />
    ))}
  </>
)

export default RetailSubscriptions
